import { useEffect } from "react";
import StyledGoodResponse from "./style";
import { ReactComponent as Bulle } from "../../assets/svgs/bulle-noir.svg";

const GoodResponse = ({ explanation, nextQuestion }) => {
  useEffect(() => {}, []);

  return (
    <StyledGoodResponse>
      <div className="inner">
        <div className="inner_title">
          <Bulle />
          <h3>Bonne réponse</h3>
        </div>

        <p>{explanation}</p>

        <button className={`btn`} onClick={() => nextQuestion()}>
          Suivant
        </button>
      </div>
    </StyledGoodResponse>
  );
};

export default GoodResponse;
