import { useEffect, useMemo, useState } from "react";
import AppStyle from "./style";
import Debug from "./components/Debug";
import Welcome from "./components/Welcome";
import Team from "./components/Team";
import Question from "./components/Question";
import Finish from "./components/Finish";
import { lsDecode, lsEncode } from "./utils/localStorage";

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [step, setStep] = useState(lsDecode("qiStep") || "welcome");
  const [question, setQuestion] = useState(lsDecode("qiQuestion", true) || 0);
  const [grade, setGrade] = useState(lsDecode("qiGrade", true) || 0);

  useEffect(() => {
    lsEncode("qiGrade", grade);
  }, [grade]);
  const displayStep = useMemo(() => {
    switch (step) {
      case "welcome":
        return <Welcome setStep={setStep} />;
      case "team":
        return <Team setStep={setStep} />;
      case "question":
        return (
          <Question
            question={question}
            setQuestion={setQuestion}
            setStep={setStep}
            setGrade={setGrade}
          />
        );
      case "finish":
        return <Finish />;
      default:
        return;
    }
  }, [step, question]);

  return (
    <AppStyle className="no-zoom">
      {urlParams.get("debug") !== null ? <Debug /> : ""}

      {displayStep}

      <div className={`background is-${step}`}></div>
      <div className="ball ball--1"></div>
      <div className="ball ball--2"></div>
      <div className="ball ball--3"></div>
    </AppStyle>
  );
};

export default App;
