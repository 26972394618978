import { useState } from "react";
import WelcomeStyle from "./style";
import { ReactComponent as Logo } from "../../assets/svgs/logo-iserba-color.svg";
import { lsEncode } from "../../utils/localStorage";

const Welcome = ({ setStep }) => {
  const [disabled, setDisaled] = useState(false);

  return (
    <WelcomeStyle>
      <div className="left">
        <div className="logo">
          <Logo />
        </div>
        <div className="bulle">
          <figure>
            <img src="/img/bulle.png" alt="bluue" />
          </figure>
        </div>
      </div>

      <div className="right">
        <div className="right_first">
          <h1>Quizz</h1>
          <span className="date">Jeudi 11 Avril 2024</span>
        </div>
        <div className="right_second">
          <span>Journée de la sécurité routière</span>
        </div>
        <div className="right_third">
          <h1>Protégeons la vie</h1>
        </div>
        <button
          className="btn"
          onClick={() => {
            setDisaled(true);

            setTimeout(() => {
              setStep("team");
              lsEncode("qiStep", "team");
            }, 200);
          }}
        >
          Commencer
        </button>
      </div>
    </WelcomeStyle>
  );
};

export default Welcome;
