import styled from "styled-components";
import { colors } from "../../assets/vars";
import { breakpoint } from "../../assets/mixins";

const StyledBadResponse = styled.div`
  display: flex;
  position: absolute;
  top: 120px;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  height: calc(100% - 120px);
  width: 100%;
  ${breakpoint.xs`
  width: 100%;
  overflow-y: auto;
  top: 100px;
`}

  .inner {
    position: relative;
    background-color: ${colors.white};
    margin: auto;
    width: 70vw;
    text-align: center;
    border: 0.5px solid ${colors.grayNickel};
    border-radius: 25px;
    padding: 50px 40px;
    ${breakpoint.xs`
    width: 100%;
    overflow-y: auto;
    border: unset;
    background-color: transparent;
  `}

    &_title {
      position: relative;
      margin-bottom: 35px;
      h3 {
        font-size: 4rem;
        font-weight: 900;
        color: ${colors.greenHaze};

        position: relative;

        ${breakpoint.xs`
        font-size: 2rem;
      `}
      }
      svg {
        position: absolute;
        width: 100px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        & path {
          fill: ${colors.appleGreen};
        }
      }
    }

    p {
      margin-bottom: 75px;
      font-size: 2.2rem;
      line-height: 1.6;
      font-weight: 700;
      ${breakpoint.xs`
      font-size: 1.3rem;
    `}
    }

    span {
      display: block;
      margin-top: 5px;
      transition: opacity 0.2s 0.5s;
      opacity: 0.9;
      font-size: 1.4rem;

      &.is-disabled {
        opacity: 0;
      }
    }
  }
`;

export default StyledBadResponse;
