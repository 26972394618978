import styled from "styled-components";
import { colors } from "../../assets/vars";
import { breakpoint } from "../../assets/mixins";

const TeamStyle = styled.section`
  display: flex;
  position: relative;
  z-index: 2;
  top: 20px;
  width: 100%;
  background: ${colors.white};
  border-radius: 25px;
  flex-direction: column;
  opacity: 0;
  animation: fadeTopPop 0.2s 0.2s ease-in-out forwards;
  overflow: hidden;
  .bulle {
    z-index: -1;
    width: 50%;
    position: absolute;
    bottom: -30%;
    left: -5%;
    transform: scale(1.5);
    svg {
      & path {
        fill: ${colors.appleGreen};
      }
    }
    ${breakpoint.xs`
    bottom: 0;
    left: 0;
    `}
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    padding: 24px 20px;
    border-bottom: 1px solid ${colors.rangoon_15};
    text-align: center;

    ${breakpoint.xs`
    flex-direction: column;
    gap: 5px;
    margin: 0;
    height: auto;
    padding: 10px 10px;
    `}

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      h1 {
        font-family: "Geologica", sans-serif;
        font-size: 3rem;
        font-weight: 700;
        ${breakpoint.xs`
        font-size: 1.3rem;
        `}
      }

      span {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-style: italic;
        font-size: 2.2rem;
        ${breakpoint.xs`
        font-size: 1.3rem;
        margin: 0;
      `}
      }

      ${breakpoint.xs`
        width: 100%;
        justify-content: flex-start;
      `}
    }

    .logo {
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      svg {
        display: block;
        width: 150px;
        margin: 0 auto 10px;
        fill: ${colors.rangoon};
        ${breakpoint.xs`
        width: 100px;
      `}
      }
      ${breakpoint.s`
      position: relative;
      margin: 0;
      left: 0;
    `}
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 40px 70px;
    margin: auto;
    background: ${colors.white};
    border: 0.5px solid ${colors.grayNickel};
    border-radius: 25px;

    ${breakpoint.xs`
    width: 90%;
    padding: 40px 10px;
  `}

    label {
      font-size: 2.1rem;
      margin-bottom: 15px;
      ${breakpoint.xs`
      font-size: 1.6rem;
  `}
    }

    input {
      height: 55px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 40px;
      padding: 0 34px;
      border: 1px solid ${colors.dove};
      border-radius: 66px;
      outline: none;
      font-size: 2.1rem;
      text-transform: uppercase;

      &:focus,
      &:hover {
        box-shadow: 0 0 0 4px ${colors.rangoon_15};
      }

      ${breakpoint.xs`
      font-size: 1.6rem;
      padding: 0 10px;
      height: 40px;
  `}
    }

    button {
      align-self: center;
    }
  }

  &.is-disabled {
    top: 0;
    opacity: 1;
    animation: fadeTopPopReverse 0.2s ease-in-out forwards;
  }
`;

export default TeamStyle;
