import { rgba } from "polished";

export const colors = {
  black: "#000",
  white: "#fff",
  white_95: rgba("#fff", 0.95),
  ecstasy: "#fb7210",
  ecstasy_15: rgba("#fb7210", 0.3),
  salem: "#0f9c39",
  salem_15: rgba("#0f9c39", 0.3),
  pea: "#1a5e2b",
  greenHaze: "#009F4A",
  rangoon: "#151610",
  dove: "#707070",
  grayNickel: "#B8B9B7",
  rangoon_15: rgba("#151610", 0.15),
  appleGreen: "#E5F5ED",
  bleachWhite: "#FEEADB",
};
