import styled from "styled-components";
import { colors } from "./assets/vars";

const AppStyle = styled.div`
  position: relative;
  isolation: isolate;
  background-color: ${colors.greenHaze};
  display: flex;
  justify-content: center;
  padding: 30px 25px;
  min-height: 100%;
  overflow-y: auto;
`;

export default AppStyle;
