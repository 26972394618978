import Axios from "axios";

/**
 * Create Axios instance with api baseURL from env vars
 */
const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_BACK_URL}`,
  crossdomain: true,
});

export default axios;
