import { useEffect } from "react";
import StyledBadResponse from "./style";
import { ReactComponent as Bulle } from "../../assets/svgs/bulle-noir.svg";

const BadResponse = ({ explanation, nextQuestion }) => {
  useEffect(() => {}, []);

  return (
    <StyledBadResponse>
      <div className="inner">
        <div className="inner_title">
          <Bulle />
          <h3>Mauvaise réponse</h3>
        </div>

        <p>{explanation}</p>

        <button className={`btn`} onClick={() => nextQuestion()}>
          Suivant
        </button>
      </div>
    </StyledBadResponse>
  );
};

export default BadResponse;
