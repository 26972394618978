import FinishStyle from "./style";
import { ReactComponent as Bulle } from "../../assets/svgs/bulle-noir.svg";
import { lsDecode, lsRemoveAll } from "../../utils/localStorage";
import { ReactComponent as Logo } from "../../assets/svgs/logo-iserba-color.svg";

const Finish = () => (
  <FinishStyle>
    <header>
      <div className="logo">
        <Logo />
      </div>

      <div className="title">
        <h1>QUIZ |</h1>
        <span>Journée de la sécurité routière</span>
      </div>

      <div className="number"></div>
    </header>

    <div className="container">
      <h1>Vous avez terminé ce quiz !</h1>
      <span>Votre score est de:</span>

      <div className="grade">
        <span>{lsDecode("qiGrade", true)}</span>
        <span>/ 10</span>
      </div>

      <button className="btn" onClick={() => lsRemoveAll()}>
        terminer
      </button>
    </div>

    <div className="bulle">
      <Bulle />
    </div>
  </FinishStyle>
);

export default Finish;
