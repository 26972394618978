import { createGlobalStyle } from "styled-components";
import { colors } from "./vars";
import { breakpoint } from "./mixins";

const RulesStyles = createGlobalStyle`
    ::selection {
        background-color: ${colors.salem};
        color: ${colors.white};
    }
   
    html, body, #root {
        height: 100% ;
        min-height: 100%;
    }
    
    body {
        color: ${colors.rangoon};
        font-family: 'Montserrat', sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.25;
        ${breakpoint.s`
            font-size: 1.4rem;
        `}
    }

    

    // no zoom
    .no-zoom {
        touch-action: manipulation;
    }

    input,
    textarea,
    button,
    select,
    a {
        -webkit-tap-highlight-color: transparent;
    }
    
    // Buttons
    .btn {
        font-family: "Geologica", sans-serif;
        height: 50px;
        padding: 0 30px;
        transition: opacity .2s, background-color .2s, color .2s;
        border-radius: 66px;
        background: transparent linear-gradient(82deg, #F59E01 0%, #E73716 100%) 0% 0% no-repeat padding-box;;
        color: ${colors.white};
        font-size: 1.6rem;
        font-weight: 700;

        &.is-disabled {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {
            background-color: transparent;
            color: ${colors.ecstasy};
        }

        ${breakpoint.m`
         font-size: 1.3rem;
         padding: 0 25px;
         height: 55px;
       `}

       ${breakpoint.s`
       padding: 0 30px;
       height: 45px;
     `}
    }
`;

export default RulesStyles;
