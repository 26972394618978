import { useState } from "react";
import TeamStyle from "./style";
import { createTeam } from "../../api/team";
import { lsEncode } from "../../utils/localStorage";
import { ReactComponent as Logo } from "../../assets/svgs/logo-iserba-color.svg";
import { ReactComponent as Bulle } from "../../assets/svgs/bulle-noir.svg";

const Team = ({ setStep }) => {
  const [disabled, setDisaled] = useState(false);
  const [team, setTeam] = useState("");

  return (
    <TeamStyle className={disabled ? "is-disabled" : ""}>
      <header>
        <div className="logo">
          <Logo />
        </div>

        <div className="title">
          <h1>QUIZ |</h1>
          <span>Journée de la sécurité routière</span>
        </div>

        <div />
      </header>

      <div className="bulle">
        <Bulle />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();

          setDisaled(true);
          lsEncode("qiTeam", team);
          createTeam({ name: team }).then((res) =>
            lsEncode("qiTeamId", res.data.data.id)
          );

          setTimeout(() => {
            setStep("question");
            lsEncode("qiStep", "question");
          }, 200);
        }}
      >
        <label htmlFor="team">Entrez votre prénom et nom :</label>

        <input
          id="team"
          type="text"
          placeholder="LA TEAM ISERBA"
          value={team}
          onChange={(el) => setTeam(el.target.value)}
        />

        <button
          className={`btn ${team.length < 1 ? "is-disabled" : ""}`}
          type="submit"
        >
          Démarrer
        </button>
      </form>
    </TeamStyle>
  );
};

export default Team;
