import { useEffect, useState } from "react";
import QuestionStyle from "./style";
import BadResponse from "../BadResponse";
import { updateTeam } from "../../api/team";
import { getAllQuestions } from "../../api/question";
import { lsEncode, lsDecode, lsRemove } from "../../utils/localStorage";
import { formattedTimer } from "../../utils/timer";
import { ReactComponent as Logo } from "../../assets/svgs/logo-iserba-color.svg";
import GoodResponse from "../GoodResponse";
import _ from "lodash";
import { ReactComponent as Bulle } from "../../assets/svgs/bulle-noir.svg";

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const Question = ({ question, setQuestion, setStep, setGrade }) => {
  const [disabled, setDisaled] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [timer, setTimer] = useState(lsDecode("qiTimer", true) || 0);
  const [error, setError] = useState(lsDecode("qiError", true) ? true : false);
  const [errorNb, setErrorsNb] = useState(lsDecode("qiErrorsNb", true) || 0);
  const [disableClick, setDisableClick] = useState(
    lsDecode("qiError", true) ? true : false
  );
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [displayGoodResponse, setDisplayGoodResponse] = useState(false);
  const [multipleAnswers, setMultipleAnswers] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Loop add +1 every second
  useEffect(() => {
    const id = setInterval(() => setTimer((oldCount) => oldCount + 1), 1000);
    getAllQuestions().then((res) => setQuestions(res.data.data));
    return () => clearInterval(id);
  }, []);

  // When timer change, add him to localStorage
  useEffect(() => {
    lsEncode("qiTimer", timer);
    console.log("timer");
  }, [timer]);

  useEffect(() => {
    console.log("change");
    setMultipleAnswers([]);
  }, [question]);

  // Triggerd when multiple answer is choosed
  const toggleMultipleAnswers = (item) => {
    const tmpAnswers = _.cloneDeep(multipleAnswers);
    if (!tmpAnswers.includes(item)) {
      tmpAnswers.push(item);
    } else {
      tmpAnswers.splice(tmpAnswers.indexOf(item), 1);
    }

    setMultipleAnswers(_.cloneDeep(tmpAnswers));
  };
  // Triggered when answer is clicked
  const checkAnswers = () => {
    setDisableClick(true);

    updateTeam(lsDecode("qiTeamId"), {
      totalQuestions: String(question + 1),
    });

    setTimeout(() => {
      if (
        questions?.[question].attributes.type === "unique" &&
        selectedAnswer.title ===
          questions?.[question].attributes.answers[selectedAnswer.key].title &&
        questions?.[question].attributes.answers[selectedAnswer.key].good
      ) {
        setDisplayGoodResponse(true);
        updateTeam(lsDecode("qiTeamId"), {
          grade: lsDecode("qiGrade", true) + 1,
        });
        setGrade(lsDecode("qiGrade", true) + 1);
      } else if (questions?.[question].attributes.type === "multiple") {
        let correct = true;
        questions?.[question].attributes.answers.forEach((answer) => {
          if (answer.good && !multipleAnswers.includes(answer.title)) {
            correct = false;
          }
        });

        if (correct) {
          setDisplayGoodResponse(true);
          updateTeam(lsDecode("qiTeamId"), {
            grade: lsDecode("qiGrade", true) + 1,
          });
          setGrade(lsDecode("qiGrade", true) + 1);
        } else {
          setError(true);
          lsEncode("qiError", 1);
          setErrorsNb(errorNb + 1);
          lsEncode("qiErrorsNb", errorNb + 1);
          updateTeam(lsDecode("qiTeamId"), {
            errors: String(errorNb + 1),
          });
        }
      } else {
        setError(true);
        lsEncode("qiError", 1);
        setErrorsNb(errorNb + 1);
        lsEncode("qiErrorsNb", errorNb + 1);
        updateTeam(lsDecode("qiTeamId"), {
          errors: String(errorNb + 1),
        });
      }
    }, 800);
  };

  const nextQuestion = () => {
    setIsLoading(true);
    setError(false);
    setDisplayGoodResponse(false);
    lsEncode("qiError", 0);
    lsRemove("qiTimerError");

    if (question + 1 < questions.length) {
      setDisableClick(false);
      setSelectedAnswer();
      setQuestion(question + 1);
      lsEncode("qiQuestion", question + 1);
      updateTeam(lsDecode("qiTeamId"), {
        timer: formattedTimer(timer),
      });
      setIsLoading(false);
    } else {
      setDisaled(true);
      setTimeout(() => {
        setStep("finish");
        lsEncode("qiStep", "finish");
        updateTeam(lsDecode("qiTeamId"), {
          timer: formattedTimer(timer),
          finish: true,
        });
        setIsLoading(false);
      }, 200);
    }
  };

  if (!questions.length) {
    return;
  }

  return (
    <QuestionStyle className={disabled ? "is-disabled" : ""}>
      <header>
        <div className="logo">
          <Logo />
        </div>

        <div className="title">
          <h1>QUIZ |</h1>
          <span>Journée de la sécurité routière</span>
        </div>

        <div className="number">
          <span>Question</span>
          <span>
            {question + 1}/{questions.length}
          </span>
        </div>
      </header>

      <div className="bulle">
        <Bulle />
      </div>

      {!error && !displayGoodResponse && !isLoading && (
        <div className="container">
          <h2>{questions[question].attributes.title}</h2>

          <div className="question">
            {questions[question].attributes.media.data ? (
              questions[
                question
              ].attributes.media.data.attributes.mime.includes("video") ? (
                <video controls>
                  <source
                    src={
                      questions[
                        question
                      ].attributes.media.data.attributes.url.includes("http")
                        ? questions[question].attributes.media.data.attributes
                            .url
                        : `https://${questions[question].attributes.media.data.attributes.url}`
                    }
                    type={
                      questions[question].attributes.media.data.attributes.mime
                    }
                  />
                </video>
              ) : (
                <figure>
                  <img
                    className="img-cover"
                    src={
                      questions[question].attributes.media.data.attributes.url
                    }
                    alt=""
                  />
                </figure>
              )
            ) : (
              ""
            )}

            <ul>
              {questions[question].attributes.answers.map((item, key) => (
                <li key={key}>
                  <button
                    className={`${disableClick ? "is-disable" : ""} ${
                      multipleAnswers.includes(item.title) ||
                      selectedAnswer?.title === item.title
                        ? "is-active"
                        : ""
                    }`}
                    onClick={() => {
                      if (questions[question].attributes.type === "unique") {
                        setSelectedAnswer({ title: item.title, key });
                      }

                      if (questions[question].attributes.type === "multiple") {
                        toggleMultipleAnswers(item.title);
                      }
                    }}
                  >
                    {alphabet[key]}. {item.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="action">
            <button
              className={`btn ${
                !selectedAnswer && multipleAnswers?.length <= 0
                  ? "is-disabled"
                  : ""
              }`}
              onClick={() => {
                checkAnswers();
              }}
              disabled={!selectedAnswer && multipleAnswers?.length <= 0}
            >
              Valider
            </button>
          </div>
        </div>
      )}

      {error && (
        <BadResponse
          explanation={questions[question].attributes.explanation}
          nextQuestion={nextQuestion}
        />
      )}

      {displayGoodResponse && (
        <GoodResponse
          explanation={questions[question].attributes.explanation}
          nextQuestion={nextQuestion}
        />
      )}
    </QuestionStyle>
  );
};

export default Question;
