import { css } from "styled-components";

const br = {
  xxs: "350px",
  xs: "870px",
  s: "980px",
  m: "1275px",
  l: "1599px",
};

export const breakpoint = Object.keys(br).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media screen and (max-width: ${br[label]}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});
