import styled from "styled-components";
import { colors } from "../../assets/vars";
import { breakpoint } from "../../assets/mixins";

const WelcomeStyle = styled.section`
  position: relative;
  display: flex;
  transition: top 0.2s, opacity 0.2s;
  width: 100%;
  background: ${colors.white};
  border-radius: 25px;

  ${breakpoint.xs`
  flex-direction: column;
 `}

  .left,
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    position: relative;
    ${breakpoint.m`
    gap: 20px;
   `}

    ${breakpoint.xs`
     width: 100%;
     align-items: center;
   `}
  }

  .left {
    .logo {
      position: absolute;
      top: 25px;
      left: 25px;
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      svg {
        display: block;
        width: 150px;
        margin: 0 auto 10px;
        fill: ${colors.rangoon};
      }
    }

    .bulle {
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translate(-50%, -50%);
      width: 100%;

      ${breakpoint.s`
      width: 90%;
      left: 50%;
      top: 70%;
    `}

      ${breakpoint.xs`
      width: 90%;
      left: 50%;
      top: 70%;
    `}

    ${breakpoint.xxs`
      width: 60%;
    `}
    }

    ${breakpoint.xs`
       height: 50%;
    `}

    ${breakpoint.xxs`
    height: 45%;
 `}
  }

  .right {
    color: ${colors.greenHaze};
    padding: 0 50px;

    ${breakpoint.xxs`
    height: 65%;
    `}
    ${breakpoint.xs`
      align-items: center;
      gap: 10px;
      height: 50%;
      padding: 0 10px;
    `}
    &_first {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      ${breakpoint.xs`
      flex-direction: column;
      align-items: flex-start;
      justify-content: unset;
      gap: 10px;
      width: 100%;
    `}
      h1 {
        font-family: "Geologica", sans-serif;
        font-size: 10rem;
        font-weight: 900;

        ${breakpoint.m`
        font-size: 7rem;
       `}

        ${breakpoint.s`
       font-size: 5rem;
      `}

      ${breakpoint.xs`
      font-size: 2rem;
      `}
      }
      span {
        &.date {
          font-family: "Geologica", sans-serif;
          color: ${colors.black};
          border: 1px solid ${colors.grayNickel};
          margin-bottom: 15px;
          padding: 5px 15px;
          border-radius: 30px;
          font-weight: 500;
          white-space: nowrap;
          ${breakpoint.m`
          font-size: 1.3rem;
         `}

          ${breakpoint.s`
          font-size: 1rem;
         `}

         ${breakpoint.xs`
           margin: 0;
        `}
        }
      }
    }

    &_second {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 4rem;
      ${breakpoint.m`
      font-size: 2.5rem;
     `}

      ${breakpoint.s`
      font-size: 1.3rem;
      width: 100%;
    `}
    }

    &_third {
      ${breakpoint.s`
      width: 100%;
      `}
      h1 {
        font-family: "Geologica", sans-serif;
        font-size: 5rem;
        font-weight: 900;
        margin-left: 50px;
        ${breakpoint.m`
        font-size: 3rem;
        margin-left: 25px;
       `}

        ${breakpoint.s`
       font-size: 2rem;
       `}

      ${breakpoint.xs`
      margin-left: 0;
      font-size: 2rem;
      `}
      }
    }

    .btn {
      margin-left: 50px;
      ${breakpoint.m`
      margin-left: 25px;
       `}

      ${breakpoint.xs`
       margin: 0;
       margin-top: 20px;
       `}
    }
  }
`;

export default WelcomeStyle;
