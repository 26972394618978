import styled from "styled-components";
import { colors } from "../../assets/vars";
import { breakpoint } from "../../assets/mixins";

const FinishStyle = styled.section`
  display: flex;
  position: relative;
  z-index: 2;
  top: 20px;
  width: 100%;
  flex-direction: column;
  opacity: 0;
  animation: fadeTopPop 0.2s 0.2s ease-in-out forwards;
  border: 0.5px solid ${colors.grayNickel};
  background: ${colors.white};
  border-radius: 25px;
  overflow: hidden;

  .bulle {
    z-index: -1;
    width: 50%;
    position: absolute;
    bottom: -30%;
    left: -5%;
    transform: scale(1.5);
    svg {
      & path {
        fill: ${colors.appleGreen};
      }
    }
    ${breakpoint.xs`
    bottom: 0;
    left: 0;
    `}
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    padding: 24px 20px;
    border-bottom: 1px solid ${colors.rangoon_15};
    text-align: center;

    ${breakpoint.xs`
    flex-direction: column;
    gap: 5px;
    margin: 0;
    height: auto;
    padding: 10px 10px;
    `}

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      h1 {
        font-family: "Geologica", sans-serif;
        font-size: 3rem;
        font-weight: 700;
        ${breakpoint.xs`
        font-size: 1.3rem;
        `}
      }

      span {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-style: italic;
        font-size: 2.2rem;
        ${breakpoint.xs`
        font-size: 1.3rem;
        margin: 0;
      `}
      }

      ${breakpoint.xs`
        width: 100%;
        justify-content: flex-start;
      `}
    }

    .logo {
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      svg {
        display: block;
        width: 150px;
        margin: 0 auto 10px;
        fill: ${colors.rangoon};
        ${breakpoint.xs`
        width: 100px;
      `}
      }
      ${breakpoint.s`
      position: relative;
      margin: 0;
      left: 0;
    `}
    }
  }

  .container {
    margin: 50px auto 0;
    max-width: 65%;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;

    ${breakpoint.xs`
    max-width: 100%;
    gap: 5px;
    overflow-y: auto;
    margin: 0;    
    padding: 20px 5px;
    height: 100%;
    gap: 20px;
    `}

    h1 {
      max-width: 675px;
      margin: 0 auto 50px;
      font-size: 4rem;
      font-weight: 900;
      line-height: 1.6;
      ${breakpoint.xs`
      font-size: 1.6rem;
      margin: 0;
   `}
    }
    span {
      font-size: 3rem;
      font-weight: 600;
      ${breakpoint.xs`
      font-size: 1.3rem;
   `}
    }

    .grade {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 80px 100px;
      border-radius: 25px;
      background: ${colors.white};
      span {
        font-size: 3rem;
        &:first-of-type {
          font-size: 6rem;
          font-weight: 900;
          color: ${colors.greenHaze};
        }
      }
    }
  }
`;

export default FinishStyle;
