import styled from "styled-components";
import { colors } from "../../assets/vars";
import { breakpoint } from "../../assets/mixins";

const QuestionStyle = styled.section`
  display: flex;
  position: relative;
  z-index: 2;
  min-height: 100%;
  width: 100%;
  background: ${colors.white};
  border-radius: 25px;
  flex-direction: column;
  opacity: 0;
  animation: fadeTopPop 0.2s 0.2s ease-in-out forwards;
  overflow: hidden;
  align-items: center;

  .bulle {
    z-index: -1;
    width: 50%;
    position: absolute;
    bottom: -30%;
    left: -5%;
    transform: scale(1.5);
    svg {
      & path {
        fill: ${colors.appleGreen};
      }
    }
    ${breakpoint.xs`
    bottom: 0;
    left: 0;
    `}
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    padding: 24px 20px;
    border-bottom: 1px solid ${colors.rangoon_15};
    text-align: center;

    ${breakpoint.xs`
    flex-direction: column;
    gap: 5px;
    margin: 0;
    height: auto;
    padding: 10px 10px;
    `}

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      h1 {
        font-family: "Geologica", sans-serif;
        font-size: 3rem;
        font-weight: 700;
        ${breakpoint.xs`
        font-size: 1.3rem;
        `}
      }

      span {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-style: italic;
        font-size: 2.2rem;
        ${breakpoint.xs`
        font-size: 1.3rem;
        margin: 0;
      `}
      }

      ${breakpoint.xs`
        width: 100%;
        justify-content: flex-start;
      `}
    }

    .number {
      width: 150px;
      display: flex;
      flex-direction: column;
      span {
        font-size: 2.7rem;
        font-weight: 700;

        &:first-of-type {
          font-family: "Geologica", sans-serif;
          font-size: 1.6rem;
          font-weight: 600;
          text-transform: uppercase;
          ${breakpoint.xs`
          font-size: 1.3rem;
          font-weight: 700;
          `}
        }
        &:last-of-type {
          font-family: "Geologica", sans-serif;
          font-weight: 700;
          font-size: 2.4rem;
          ${breakpoint.xs`
          font-size: 1.3rem;
          font-weight: 500;
          `}
        }
      }

      ${breakpoint.xs`
      width: 100%;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    `}
    }

    .logo {
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      svg {
        display: block;
        width: 150px;
        margin: 0 auto 10px;
        fill: ${colors.rangoon};
        ${breakpoint.xs`
        width: 100px;
      `}
      }
      ${breakpoint.s`
      position: relative;
      margin: 0;
      left: 0;
    `}
    }
  }

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 65%;
    width: 100%;
    padding: 25px 0;
    gap: 25px;

    ${breakpoint.xs`
    max-width: 100%;
    gap: 20px;
    overflow-y: auto;
    margin: 0;    
    padding: 20px 5px;
    `}

    h2 {
      font-family: "Geologica", sans-serif;
      font-weight: 500;
      max-width: 675px;
      font-size: 2rem;
      text-align: center;
      line-height: 1.6;
      ${breakpoint.xs`
       font-size: 1.6rem;
    `}
    }

    .question {
      display: flex;
      align-items: center;
      gap: 40px;

      ${breakpoint.xs`
      flex-direction: column;
      gap: 20px;
      
   `}

      video,
      figure {
        overflow: hidden;
        flex: 1;
      }

      video {
        aspect-ratio: 16 / 9;
      }

      figure {
        border-radius: 17px;
      }

      ul {
        height: 100%;
        display: flex;
        flex: 1;
        gap: 20px;
        flex-direction: column;
        justify-content: flex-start;

        button {
          font-family: "Geologica", sans-serif;
          font-size: 1.6rem;
          width: 100%;
          padding: 10px 30px;
          border-radius: 35px;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border: 1px solid ${colors.dove};
          text-align: left;
          background-color: ${colors.white};

          &:hover {
            box-shadow: 0 0 0 2px ${colors.rangoon_15};
          }

          &.is-disable {
            pointer-events: none;
          }

          &.is-active {
            background-color: ${colors.black};
            color: ${colors.white};

            &.is-error {
              background-color: ${colors.ecstasy};
            }
          }
        }
      }
    }

    .action {
      text-align: center;
    }
  }

  &.is-disabled {
    top: 0;
    opacity: 1;
    animation: fadeTopPopReverse 0.2s ease-in-out forwards;
  }
`;

export default QuestionStyle;
